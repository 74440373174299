.lead-detail-tabs {
    .inventory-funding-doc-verfiaction {

        .menu-tab-left-panel {
            padding-left: 0px;
            padding-right: 0px;

            ul {
                li {
                    a {
                        margin-bottom: 0px;
                        padding: 10px 33px;

                        &.active,
                        &:hover {
                            background: #EFF2F9;
                        }

                        &::before {
                            content: '';
                            background: $heading-color;
                            height: 5px;
                            width: 5px;
                            border-radius: 100%;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            margin-left: -10px;
                        }
                    }


                }
            }

        }

        .image-tab-panel {
            width: calc(70% - 20px);
        }

        .form-tab-right-panel {
            width: calc(30% - 20px);
        }

        .doument-gallery-tabs {
            .image-opt {
                position: inherit;
            }
        }

        .image-tab-panel,
        .form-tab-right-panel {
            margin-top: 0px !important;
        }

        .doument-gallery-tabs {
            h3 {
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                text-align: left;
                color: $heading-color;

            }

            .verfication-img {
                padding: 30px 0 10px;
            }
        }
    }

}

.verfiaction-acttion-btn {
    .btn-line {
        min-width: 100%;
    }
}

.upload-info-verfication {
    .image-form-outer {
        width: 70%;

        .image-tab-panel {
            width: 100%;
            margin-top: 0px;

            .doument-gallery-tabs {
                display: flex;
                align-items: center;

                .doc-list-image {
                    width: calc(100% - 20px);

                    .dropdown {
                        position: relative;

                        .dropdown-menu {
                            width: 400px;

                            &.show {
                                overflow: auto;
                            }
                        }
                    }

                    .file-submit-sec {
                        width: 100%;
                    }

                    &:first-child {
                        padding-right: 20px;
                        border-right: 1px solid $border-color;
                    }

                    &:nth-child(2) {
                        padding-left: 20px;
                    }
                }

                .image-opt {
                    position: inherit;
                }

                .image-tagning-drop-down {
                    position: inherit;
                    margin-top: 0px;

                    button {
                        border: 0px;
                        width: 220px;
                    }
                }

                .btn-submission-approved {
                    display: none;
                }
            }

        }

    }

    .form-tab-right-panel {
        width: 30%;
        margin-top: 0px;
    }

    .material {
        input {
            &.form-input {
                height: 42px;
            }
        }
    }

}

.right-panel-scroll {
    max-height: 503px;
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 5px 20px 0 20px;
    margin-right: -20px;
}
.btn-submission-approved {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}
.car-valuation-tab {
    .image-form-outer {
        width: 40%;
        height: auto;

        .image-tab-panel {
            width: 100%;
        }
    }

    .form-tab-right-panel {
        width: 60%;

        .material {
            input {
                &.form-input {
                    height: 42px;
                }
            }
        }
    }

    .image-opt {
        position: inherit;
    }

    .lead-form-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        h2 {
            padding-bottom: 0px;
        }
    }

}

.valuation-form-card-outer {
    display: flex;
    justify-content: space-between;

    .car-valuation-form {
        width: 49%;
        
    }

    .car-valuation-card-outer {
        width: 49%;
        padding: 0px 0 0 15px;

        .valuation-card {
            box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.04);
            border: 1px solid rgba(224, 224, 224, 1);
            border-radius: 10px;
            margin-bottom: 10px;
            color: $heading-color;
            padding: 20px;

            span {
                font-size: 22px;
                font-weight: 600;
                line-height: 33px;
                display: block;
            }

            label {
                font-size: 13px;
                font-weight: 500;
                line-height: 20px;
            }

            p {
                font-size: 10px;
                font-weight: 400;
                line-height: 15px;
                color: rgba($heading-color, 0.5);

            }
        }
        
        &.p-r-15{
            padding-right: 15px !important;
        }
    }

    .edit-laon-amt-txt {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: $accent-color;
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
    }

    .btn-primary {
        min-width: auto;
        width: 100%;
    }

    .image-opt {
        position: inherit;
    }
}

.edit-loan-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;

    span {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: $heading-color;
    }

    i {
        cursor: pointer;
        font-size: 18px;
        color: $heading-color;

    }
}

.disbursal-credit-approval {
    background-color: $white;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 10px;

    .disbursal-credit-outer {
        padding: 20px 0;

        .disbursal-opt-card-outer {
            display: flex;

            .disbursal-card {
                box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.04);
                border: 1px solid rgba(224, 224, 224, 1);
                border-radius: 10px;
                width: 220px;
                padding: 30px 10px;
                margin-right: 10px;

                span {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 27px;
                    text-align: center;
                    color: $heading-color;
                    display: block;
                }

                label {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    text-align: center;
                    color: $heading-color;
                    display: block;

                }
            }
        }
    }
}

.disbursal-credit-peanding-table-outer {
    table {
        tr {

            th,
            td {
                white-space: normal;

                &:first-child {
                    width: 15%;
                }

                &:nth-child(2) {
                    width: 6%;
                }

                &:nth-child(3) {
                    width: 10%;
                }

                &:nth-child(4) {
                    width: 10%;
                }

                &:nth-child(5) {
                    width: 10%;
                }

                &:nth-child(6) {
                    width: 10%;
                }

                &:nth-child(7) {
                    width: 10%;
                }

                &:nth-child(8) {
                    width: 12%;
                }

                &:nth-child(9) {
                    width: 10%;
                }
            }
        }
    }
}

.credit-disbural-amt-detail {
    padding: 20px 0;

    ul {
        display: flex;

        li {
            padding-right: 40px;
            color: $heading-color;

            label {
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                text-align: left;
                display: block;
            }

            span {
                font-size: 18px;
                font-weight: 600;
                line-height: 27px;
                text-align: center;

            }
        }
    }
}

.loan-dictionary-tab {
    background-color: $white;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 10px;

    .loan-disctory-table-outer {
        table {
            tbody {
                border: 1px solid rgba(0, 0, 0, 0.06);
                border-radius: 8px;
                border-top: 0px;
            }

            tr {
                th {
                    border-radius: 8px 0 0 0;

                    &:last-child {
                        border-radius: 0 8px 0 0;
                    }
                }

                th,
                td {
                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }

    .lead-form-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        h3 {
            font-size: 22px;
            font-weight: 500;
            line-height: 30px;
            text-align: left;
            color: $heading-color;
        }

        .btn-line {
            min-width: auto;
            padding: 0px 20px;
            display: flex;
            align-items: center;

            span {
                margin-left: 5px;
            }
        }
    }
}

.vehicle-photos-detail {
    padding-top: 30px;

    .heading-txt {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            font-size: 22px;
            font-weight: 500;
            line-height: 30px;
            text-align: left;
            color: $heading-color;
        }

        .btn-line {
            min-width: auto;
            padding: 0px 20px;
            display: flex;
            align-items: center;

            span {
                margin-left: 5px;
            }
        }
    }

    .image-opt {
        position: inherit;

        .ic-Download-icon {
            font-size: 14px;
        }
    }

    .verfication-img {
        padding: 20px 0;
    }
}

.photos-gallery-sec {
    background-color: rgba(241, 244, 252, 1);
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;

    .doument-view-list-gallery {
        span {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
            color: $heading-color;
        }
    }
}

.loan-financer-detail {
    padding: 30px 0px;

    h3 {
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: $heading-color;
        padding-bottom: 10px;
        display: block;

    }
}

.doc-varfiaction-tab-outer {
    .tab-list {
        display: inline-block !important;
        width: 100%;
    }

    .nav-pills {
        background: transparent;
        width: 100%;
        box-shadow: none;
        margin: 0px;
        text-align: left;
        padding-left: 0px;
        padding-right: 0px;

        .nav-item {
            a {
                background: transparent;
                border-radius: 0px;
                justify-content: flex-start;
                margin-right: 0px;

                span {
                    margin-left: 10px;
                }

                &.active,
                &:hover {
                    background: rgba(239, 242, 249, 1);
                    color: $heading-color;

                }

                &.completed {
                    &:after {
                        content: "\e927";
                        color: $highlight-color;
                        font-family: "dc";
                        position: absolute;
                        right: 20px;
                        left: inherit;
                        border: 0px;
                        font-weight: bold;
                    }

                }
            }
        }
    }

}

.inventory-funding-doc-verfiaction {

    .icn-pre-slide,
    .icn-next-slide {
        top: 39% !important;

        button {
            background: $white;
            border-radius: 30px;
            height: 30px;
            width: 30px;
            border: 1px solid $border-color;
        }
    }

    .image-tab-panel {
        min-height: 590px;
    }

}

.upload-info-verfication {
    .verfication-img {
        position: relative;
    }

    .icn-pre-slide,
    .icn-next-slide {
        button {
            background: $white;
            border-radius: 30px;
            height: 30px;
            width: 30px;
            border: 1px solid $border-color;
        }
    }

    .icn-pre-slide {
        left: -20px !important;
    }

    .icn-next-slide {
        right: -20px !important;
    }
}

.car-valuation-tab{
  
    .icn-pre-slide,
    .icn-next-slide {
        top: 36% !important;
        button {
            background: $white;
            border-radius: 30px;
            height: 30px;
            width: 30px;
            border: 1px solid $border-color;
           
        }
    }
    .icn-pre-slide {
        left: 10px !important;
    }

    .icn-next-slide {
        right: 10px !important;
    }
 
}
.calculator-popup-form{
    fieldset{
        margin-bottom: 20px;
    }
    .modal-main {
        width: 630px;
    }

}
.inventory-funding-list-table{
    table{
        tr{
            th{
                &:first-child{
              width: 13%;  
            }
            &:nth-child(2){
                width: 13%;  
              }
              &:nth-child(9){
                width: 16%;  
              }
        }
        }
    }
}
.cancel-lead-popup{
    .modal-main {
        width: 400px;
    }
    .btn-primary{
        min-width: 140px;
    }
}
.marked-submission-popuop{
    .modal-main {
        width: 410px;
    }
    .btn-primary{
        min-width: 140px;
    }
    h3{
        color: $heading-color;
    }
}

.approved-tag-icn{
  position: absolute;
  right: 10px;
  color: #30b38a;
  top: 9px;
  font-size: 16px;
}
.verify-doc-upload-img{
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    .img-upload-outer-input{
        width: 105px;
        height: 42px;
        cursor: pointer;
        overflow: hidden;
        input{
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
}

.list-input-field {
    position: relative;
    input {
        background: #ffffff;
        border: 1px solid #bbb;
        border-radius: 8px;
        width: 100%;
        padding: 9px;
        font-size: 11px;
        height: 42px !important;
    }
  }


.upload-id-proof-btn{
    position: relative;
    text-align: center;
    padding-bottom: 20px;

    input{
        position: absolute;
        opacity: 0;
        height: 100%;
        width: 200px;
        cursor: pointer;
    }

    button{
        cursor: pointer;
    }
}

.download-btn{
    min-width: 140px;
  margin-left: 12px;
}

.button-like-link {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    color: black; /* or any color you prefer */
    text-decoration: underline;
}
.loan-limit-breached {
    color: red !important;
}

.image-opt-opsee {
   top: unset !important; 
}
.record-found {
    padding: 22px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    display: block;
    word-wrap: break-word;
}

.details-button {
    min-width: 185px !important;
}

.initiate-chat {
    color: #0bbddd !important;
    cursor: pointer;
}